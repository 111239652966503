import React from 'react'
import {
  CardImage,
  PromoBubble,
  PromoLabel,
  CheckboxAtom,
  ChevronDown,
  highestPriorityPromo,
  Link,
  ProductCard,
  ProductStatusLabel,
  Box,
  PROMO_BUBBLE_CONSTANTS,
  BASE,
  PROMO_LABEL_CONSTANTS,
  LG,
  MarketingLabel
} from 'chemistry'
import { CardPrice } from 'client/common/components/Card/CardPrice'
import { Text } from '@chakra-ui/react'
import { CardImageLabel } from 'client/common/components/Card/CardImageLabel'
import { extractImageProps } from 'client/common/components/Card/imageProps'
import { extractPriceProps } from 'client/common/components/Card/priceProps'
import { extractDiscountProps } from 'client/common/components/Card/discountProps'
import { CardSchema } from 'client/fragments/listerFragment/components/SchemaOrg/CardSchema'
import { statusColorMapping } from 'client/common/components/Card/cardStatusColor'
import { RatingStars } from 'client/common/components/Card/RatingStars'
import { createLogger } from 'common/log'
import { BRICO } from 'client/common/utils'

const DISABLED_STATUSES = ['Binnenkort verkrijgbaar', 'Bientôt disponible']
const logger = createLogger('lister/Card')

const isLarge = sizes => sizes.includes('lg')
const getVlpLabelStyles = sizes => ({
  [BASE]: { display: 'flex' },
  ...(isLarge(sizes) ? { [LG]: { display: 'none' } } : {})
})
const getVlpBubbleStyles = ({ sizes, format }) => ({
  [BASE]: { display: 'none' },
  ...(isLarge(sizes) ? { [LG]: { display: 'block', position: 'absolute' } } : {}),
  ...(format === BRICO ? { top: '4px', left: '4px' } : { top: 0, left: 0 })
})

const MarketingLabelBox = ({ sizes, url, labelText, metadata, hasCheckbox, sx, ...props }) => (
  <Box
    sx={{
      position: 'absolute',
      transformOrigin: 'top left',
      left: hasCheckbox ? '26px' : 0,
      top: 0,
      ...(isLarge(sizes)
        ? {
            [LG]: {
              transform: 'none',
              left: 'auto',
              top: 'auto',
              right: 0,
              bottom: 0
            }
          }
        : {}),
      ...sx
    }}
    {...props}
  >
    <MarketingLabel url={url} labelText={labelText} metadata={metadata} />
  </Box>
)

export const Card = props => {
  const {
    sizes = ['sm', 'md', 'lg'],
    fullPriceProps,
    disableStatus,
    t: translations,
    format,
    lang,
    categoryCode,
    availabilityStatus,
    isChecked,
    onProductCheck,
    target,
    showBVRatingOnListerCards,
    withArrow = false,
    imgProps,
    marketingLabels
  } = props
  const hasPriceError = !!fullPriceProps?.errors?.length
  if (hasPriceError) {
    logger.error(`PRICE_ERROR, product ${format} ${props?.id} has corrupted price on lister`)
  }

  const imageProps = extractImageProps(props)
  const winnerPromo = highestPriorityPromo(props)
  const priceComponentProps = extractPriceProps({ ...fullPriceProps })
  const promotionType = fullPriceProps.type
  const discountProps = extractDiscountProps(props, promotionType, winnerPromo)
  const disabledKey = disableStatus?.isDisabled && translations.temporarilyDisabled
  const isDisabledStatus = DISABLED_STATUSES.includes(availabilityStatus)
  const [marketingLabel] = Array.isArray(marketingLabels) ? marketingLabels : []

  return (
    <>
      {!categoryCode && <CardSchema {...props} />}
      <ProductCard
        sizes={sizes}
        link={props.link}
        target={target}
        disabled={disabledKey || isDisabledStatus || (onProductCheck && !isChecked)}
      >
        {onProductCheck && <CheckboxAtom isChecked={isChecked} onChange={() => onProductCheck(props.id)} />}
        <ProductCard.Image>
          <div className='relative'>
            <CardImage {...imageProps} imgProps={imgProps} />
            {!hasPriceError && <CardImageLabel disabledKey={disabledKey} discountProps={discountProps} />}
            {fullPriceProps.isVLP && (
              <Box sx={getVlpBubbleStyles({ format, sizes })}>
                <PromoBubble
                  format={format}
                  lang={lang}
                  size={PROMO_BUBBLE_CONSTANTS.SMALL}
                  colorScheme={PROMO_BUBBLE_CONSTANTS.FIXED_LOW_PRICE}
                  content={{ title: translations.vlp, supertext: '' }}
                />
              </Box>
            )}
            {marketingLabel && (
              <MarketingLabelBox
                url={marketingLabel?.url}
                labelText={marketingLabel?.labelText}
                metadata={marketingLabel?.metadata}
                sizes={sizes}
                hasCheckbox={onProductCheck}
              />
            )}
          </div>
        </ProductCard.Image>

        <ProductCard.Title>
          <Text fontSize='sm' fontFamily='bold' wordBreak='break-word' data-testid='card-title'>
            {props?.title}
          </Text>
        </ProductCard.Title>

        <ProductCard.Extra>
          {showBVRatingOnListerCards && !!props?.reviews?.count && (
            <RatingStars
              rating={props?.reviews?.rating || 0}
              label={`(${props?.reviews?.count || '0'})`}
              productId={props?.id}
            />
          )}
          {availabilityStatus || disabledKey ? (
            <ProductStatusLabel
              disabledKey={disabledKey}
              availabilityStatus={availabilityStatus}
              color={statusColorMapping(availabilityStatus, disabledKey)}
            />
          ) : (
            <Text>{props?.category?.title}</Text>
          )}
        </ProductCard.Extra>

        {fullPriceProps.isVLP && (
          <PromoLabel
            size={PROMO_LABEL_CONSTANTS.SIZE.small}
            variant={PROMO_LABEL_CONSTANTS.VARIANT.alwaysLowPrice}
            format={format}
            lang={lang}
            sx={getVlpLabelStyles(sizes)}
          />
        )}
        {!disabledKey && <CardPrice {...priceComponentProps} promotionType={promotionType} />}
        {withArrow && (
          <Link variant='primary' target={target}>
            <ChevronDown color='white' />
          </Link>
        )}
      </ProductCard>
    </>
  )
}
